import React from 'react'
import { navigate } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import { colors, globals, s, q } from '../../style'
import { url } from '../../utils'
import logosvg from '../../assets/logo'
import LanguageButton from '../../menus/LanguageButton/LanguageButton'

import facebook from '../../assets/facebookIcon'
import linkedin from '../../assets/linkedinIcon'
import instagram from '../../assets/instagramIcon'
import youtube from '../../assets/youtubeIcon'
import twitter from '../../assets/twitterIcon'

const Socials = {
  facebook,
  instagram,
  linkedin,
  twitter,
  youtube,
}

const FooterClassic = ({ language, theme, dispatch }) => {
  const data = useStaticQuery(graphql`
    {
      footers: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/footers/" } }
      ) {
        edges {
          node {
            frontmatter {
              language
              pages {
                page_slug
                page_title
                isSection
                sections {
                  section_slug
                  section_title
                  absolute
                }
              }
              pages_secondary {
                page_slug
                page_title
                # absolute
              }
              contact_data {
                company_email
                company_krs
                company_name
                company_phone
                company_regon
                company_tax
                company_address {
                  lines {
                    line
                  }
                  link
                }
              }
              socials {
                facebook
                instagram
                linkedin
                twitter
                youtube
              }
            }
          }
        }
      }
    }
  `)

  const footerData = data?.footers?.edges
    .filter((e) => e.node.frontmatter?.language === language)
    .map(({ node }) => node.frontmatter)
    .filter((_, id) => id === 0)[0]

  return (
    <footer css={[sFooter, sFooterTheme(theme), { contentVisibility: 'auto' }]}>
      <div css={sFooterContainer}>
        <div css={sDiv1}>
          <div css={sDiv1.container}>
            <div
              onClick={() => navigate(`/${language}`)}
              onKeyPress={() => navigate(`/${language}`)}
              role="button"
              tabIndex={0}
              css={sLogo}>
              {logosvg}
            </div>
            <div css={sDiv1.links}>
              {footerData?.pages_secondary?.map((page, id) => (
                <div
                  key={id}
                  css={sDiv1.link}
                  onClick={() =>
                    navigate(
                      url(
                        language,
                        page.page_slug,
                        null,
                        page.isSection,
                        page.absolute
                      )
                    )
                  }
                  onKeyPress={() =>
                    navigate(
                      url(
                        language,
                        page.page_slug,
                        null,
                        page.isSection,
                        page.absolute
                      )
                    )
                  }
                  role="button"
                  tabIndex={0}>
                  {page.page_title}
                </div>
              ))}
            </div>
          </div>
          <div css={[sDiv1.socials, { [s.sm_down]: { width: '100%' } }]}>
            {footerData?.socials?.facebook && (
              <a
                css={colorOnhover(theme)}
                href={footerData?.socials.facebook}
                rel="noreferrer"
                target="_blank">
                {Socials.facebook}
              </a>
            )}
            {footerData?.socials?.youtube && (
              <a
                css={colorOnhover(theme)}
                href={footerData?.socials.youtube}
                rel="noreferrer"
                target="_blank">
                {Socials.youtube}
              </a>
            )}
            {footerData?.socials?.twitter && (
              <a
                css={colorOnhover(theme)}
                href={footerData?.socials.twitter}
                rel="noreferrer"
                target="_blank">
                {Socials.twitter}
              </a>
            )}
            {footerData?.socials?.linkedin && (
              <a
                css={colorOnhover(theme)}
                href={footerData?.socials.linkedin}
                rel="noreferrer"
                target="_blank">
                {Socials.linkedin}
              </a>
            )}
            {footerData?.socials?.instagram && (
              <a
                css={colorOnhover(theme)}
                href={footerData?.socials.instagram}
                rel="noreferrer"
                target="_blank">
                {Socials.instagram}
              </a>
            )}
            <LanguageButton
              footerStyle
              theme={theme}
              dispatch={dispatch}
              language={language}
            />
          </div>
        </div>
        <div css={sDiv2}>
          {footerData?.pages
            .filter((page) => page.sections && page.sections.length > 0)
            .map((page, id) => (
              <div key={id}>
                <div
                  css={sLink.page}
                  onClick={() =>
                    navigate(
                      url(
                        language,
                        page.page_slug,
                        null,
                        page.isSection,
                        page.absolute
                      )
                    )
                  }
                  onKeyPress={() =>
                    navigate(
                      url(
                        language,
                        page.page_slug,
                        null,
                        page.isSection,
                        page.absolute
                      )
                    )
                  }
                  role="button"
                  tabIndex={0}>
                  {page.page_title}
                </div>
                <div>
                  {page.sections.map((section, id) => (
                    <div
                      css={sLink.section}
                      onClick={() =>
                        navigate(
                          url(
                            language,
                            page.page_slug,
                            section.section_slug,
                            page.isSection,
                            page.absolute,
                            section.absolute
                          )
                        )
                      }
                      onKeyPress={() =>
                        navigate(
                          url(
                            language,
                            page.page_slug,
                            section.section_slug,
                            page.isSection,
                            page.absolute,
                            section.absolute
                          )
                        )
                      }
                      role="button"
                      tabIndex={0}
                      key={id}>
                      {section.section_title}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          <div
            css={{
              [s.md]: {
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              },
              [s.lg]: {
                justifyContent: 'space-between',
              },
            }}>
            {footerData?.pages
              .filter((page) => !page.sections || page.sections.length <= 0)
              .map((page, id) => (
                <div
                  key={id}
                  css={sLink.page}
                  onClick={() =>
                    navigate(
                      url(
                        language,
                        page.page_slug,
                        null,
                        page.isSection,
                        page.absolute
                      )
                    )
                  }
                  onKeyPress={() =>
                    navigate(
                      url(
                        language,
                        page.page_slug,
                        null,
                        page.isSection,
                        page.absolute
                      )
                    )
                  }
                  role="button"
                  tabIndex={0}>
                  {page.page_title}
                </div>
              ))}
          </div>
        </div>
        <div css={sDiv3(theme)}>
          <div className="footer-brand">
            {footerData?.contact_data.company_name}
          </div>
          <div className="footer-address">
            <a
              href={footerData?.contact_data.company_address.link}
              rel="noreferrer"
              target="_blank">
              {footerData?.contact_data.company_address.lines.map(
                (line, id) => (
                  <span key={id}>{line.line}</span>
                )
              )}
            </a>
          </div>
          <div className="footer-mail">
            <a href={`mailto:${footerData?.contact_data.company_email}`}>
              {footerData?.contact_data.company_email}
            </a>
          </div>
          <div className="footer-phone">
            <a href={`tel:${footerData?.contact_data.company_phone}`}>
              {footerData?.contact_data.company_phone}
            </a>
          </div>
          <div className="footer-tax">
            {footerData?.contact_data.company_tax && (
              <span>NIP/VAT: {footerData?.contact_data.company_tax}</span>
            )}
          </div>
          <div className="footer-rights">© {new Date().getFullYear()}</div>
        </div>
      </div>
    </footer>
  )
}

const sFooter = {
  width: '100vw',
  [s.sm_down]: { marginTop: globals.spacing.between.mobile },
  [s.md]: { marginTop: globals.spacing.between.desktop },
}

const colorOnhover = (theme) => ({
  transition: 'color 0.25s ease-out',
  '&:hover': {
    [s.hover]: {
      color: colors[theme].main,
    },
  },
})

const sFooterTheme = (theme) => ({
  background: `linear-gradient(${colors[theme].footer.angle}deg, ${colors[theme].footer.background[0]}, ${colors[theme].footer.background[1]})`,
  color: colors[theme].footer.text,
})

const sFooterContainer = {
  maxWidth: globals.maxWidth,
  margin: '0 auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [s.lg_only]: {
    padding: '0 4rem',
  },
}

const sLogo = {
  img: {
    width: 128,
    height: 48,
    filter: 'grayscale(1) brightness(5)',
  },
  color: 'currentColor',
  [s.sm_down]: {
    paddingTop: '2rem',
    paddingBottom: '4rem',
    img: { maxWidth: globals.menu.logo.mobile.maxWidth },
  },
  [s.md]: {
    // paddingBottom: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    img: { maxWidth: globals.menu.logo.maxWidth },
  },
  [s.lg]: {
    paddingBottom: 0,
  },
}

const sDiv1 = {
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  [s.sm_down]: {
    flexDirection: 'column',
    padding: '2rem',
  },
  [s.md]: {
    padding: '4rem',
  },
  [s.lg]: {
    padding: '6rem 0',
  },

  container: {
    display: 'flex',
    [s.sm_down]: {
      width: '100%',
      flexDirection: 'column',
    },
  },

  socials: {
    display: 'flex',
    a: {
      transition: 'opacity 0.25s ease-out',
      opacity: 1,
      '&:hover': {
        [s.hover]: {
          // opacity: 0.3,
        },
      },
      paddingRight: '1rem',
      [s.md_down]: {
        paddingRight: '2rem',
      },
      svg: {
        transition: 'color 0.25s ease-out',
        width: 24,
        height: 24,
      },
    },
  },

  links: {
    display: 'flex',
    flexDirection: 'row',
    [s.sm_down]: {
      justifyContent: 'space-between',
      paddingBottom: '4rem',
    },
    [s.xs]: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingBottom: '3rem',
    },
    [s.sm]: {
      textAlign: 'center',
      flexWrap: 'wrap',
    },
    [s.md]: {
      // paddingBottom: '4rem',
      flexWrap: 'nowrap',
      margin: '0 2rem',
    },
    [s.lg]: {
      paddingBottom: 0,
      margin: '0 4rem',
    },
  },

  link: {
    ...q.center,
    transition: 'opacity 0.25s ease-out',
    opacity: 0.3,
    cursor: 'pointer',
    '&:hover': {
      [s.hover]: {
        opacity: 1,
      },
    },
    marginRight: '1.5rem',
    fontSize: 13,
    [s.sm_down]: {
      fontSize: 16,
      paddingBottom: '1rem',
    },
    [s.xs]: {
      paddingBottom: '1rem',
    },
    fontWeight: 700,
  },
}

const sDiv2 = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  [s.sm_down]: {
    flexWrap: 'wrap',
    padding: '2rem',
  },
  [s.xs]: {
    flexDirection: 'column',
  },
  [s.md]: {
    padding: '0 4rem 4rem',
  },
  [s.md_only]: {
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  [s.lg]: {
    padding: '0 0 6rem',
  },
  '& > div': {
    width: '100%',
    [s.sm_down]: {
      width: '50%',
      paddingBottom: '1rem',
    },
    [s.md_only]: {
      // width: '33%',
      paddingBottom: '2rem',
    },
    [s.xs]: {
      width: '100%',
    },
  },
}

const sDiv3 = (theme) => ({
  borderTop: `2px solid ${colors[theme].footer.background[0]}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > div': {
    [s.sm_down]: {
      width: '50%',
      paddingBottom: '1rem',
    },
    [s.xs]: {
      width: '100%',
    },
  },
  [s.sm_down]: {
    flexWrap: 'wrap',
    padding: '4rem 2rem 4rem',
  },
  [s.xs]: {
    flexDirection: 'column',
  },
  [s.md]: { padding: '4rem', flexWrap: 'wrap' },
  [s.lg]: { padding: '4rem 0' },
  '.footer-address, .footer-phone, .footer-mail, .footer-tax': {
    fontSize: 12,
    opacity: 0.3,
    fontWeight: 400,
    [s.sm_down]: {
      fontSize: 14,
    },
  },

  '.footer-address, .footer-phone, .footer-mail': {
    cursor: 'pointer',
    transition: 'opacity 0.25s ease-out',
    '&:hover': {
      opacity: 1,
    },
  },

  '.footer-brand, .footer-rights': {
    opacity: 0.3,
    fontWeight: 700,
    fontSize: 15,
  },

  '.footer-address': {
    span: {
      paddingRight: 5,
    },
  },
})

const sLink = {
  page: {
    transition: 'opacity 0.25s ease-out',
    cursor: 'pointer',
    fontSize: '1rem',
    paddingBottom: '1rem',
    textTransform: 'uppercase',
    [s.sm_down]: {
      paddingTop: '1rem',
    },
    [s.md_only]: {
      paddingBottom: '1rem',
    },
    fontWeight: 700,
    '&:hover': {
      [s.hover]: {
        opacity: 0.6,
      },
    },
  },
  section: {
    cursor: 'pointer',
    transition: 'opacity 0.25s ease-out',
    fontSize: '0.875rem',
    paddingBottom: '0.5rem',
    fontWright: 300,
    opacity: 0.3,
    '&:hover': {
      [s.hover]: {
        opacity: 1,
      },
    },
  },
}

// Footer © {new Date().getFullYear()}

export default FooterClassic
